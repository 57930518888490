import React from "react";
import {Navigate, useRoutes} from "react-router-dom"
import LoginView from "../views/LoginView";
import CharacterView from "../views/CharacterView";
import CityView from "../views/CityView";
import MapsView from "../views/MapsView";
import DungeonsView from "../views/DungeonsView";
import ArenaView from "../views/ArenaView";
import MarketView from "../views/MarketView";
import GatheringView from "../views/GatheringView";
import GuildView from "../views/GuildView";
import { useTranslation } from 'react-i18next';

export default function DashboardRoutes() {
    const { t } = useTranslation();

    return useRoutes([
        {
            path: "/",
            children: [
                {
                    path: `${t('routes.character')}`,
                    element: <CharacterView />,
                },
                {
                    path: `${t('routes.login')}`,
                    element: <LoginView />,
                },
                {
                    path: `${t('routes.city')}`,
                    element: <CityView />,
                },
                {
                    path: `${t('routes.maps')}`,
                    element: <MapsView />,
                },
                {
                    path: `${t('routes.dungeons')}`,
                    element: <DungeonsView />,
                },
                {
                    path: `${t('routes.arena')}`,
                    element: <ArenaView />,
                },
                {
                    path: `${t('routes.market')}`,
                    element: <MarketView />,
                },
                {
                    path: `${t('routes.gathering')}`,
                    element: <GatheringView />,
                },
                {
                    path: `${t('routes.guild')}`,
                    element: <GuildView />,
                },
                {
                    path: "*",
                    element: <Navigate replace to={t('routes.character') as string} />,
                },
            ],
        },
    ])
}
