import { MenuItem, MenuList, Typography } from '@mui/material';
import React from 'react';
import AuthWrapper from './AuthWrapper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState } from '../app/slices/authSlice';
import { RootState } from '../app/store';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(clearAuthState())
  }
  const email = useSelector((state: RootState) => state.email);

  const menuItems = [
    {
        route: "routes.character",
        text: "menu.list.character"
    },
    {
        route: "routes.city",
        text: "menu.list.city"
    },
    {
        route: "routes.maps",
        text: "menu.list.maps"
    },
    {
        route: "routes.dungeons",
        text: "menu.list.dungeons"
    },
    {
        route: "routes.arena",
        text: "menu.list.arena"
    },
    {
        route: "routes.market",
        text: "menu.list.market"
    },
    {
        route: "routes.gathering",
        text: "menu.list.gathering"
    },
    {
        route: "routes.guild",
        text: "menu.list.guild"
    },
  ];

  return (
    <aside>
        <Typography>{t('menu.menu')}</Typography>
        <Typography>{t('menu.welcome', { name: email })}</Typography>
        <MenuList component="nav">
            {menuItems.map((element, index) => (
                <CustomLink to={t(element.route) as string} key={index}>
                    <MenuItem
                        disableGutters={true}
                        divider
                        >
                        {t(element.text)}
                    </MenuItem>
                </CustomLink>
            ))}
            <MenuItem onClick={logOut}>
                {t('menu.list.logout')}
            </MenuItem>
        </MenuList>
    </aside>
  );
};

interface LayoutProps {
  children: React.ReactNode;
}

const LoggedLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <AuthWrapper>
        <main>
            <Sidebar />
            {children}
        </main>
    </AuthWrapper>
  );
};

function CustomLink({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
  
    return (
      <Link to={to} {...props}>
        {React.cloneElement(children as JSX.Element, {
          selected: !!match
        })}
      </Link>
    );
  }

export default LoggedLayout;