import React from 'react';
import { Box } from '@mui/material';
import fresco from '../assets/img/fresco.png';

const FrescouesFullWidth: React.FC = () => {
    return (
        <Box sx={{ width: '100%', height: '32px', backgroundImage: `url(${fresco})`,}}>
        </Box>
    );
};

export default FrescouesFullWidth;
