import React from 'react';
import {Navigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { useTranslation } from 'react-i18next';
import FrescouesFullWidth from './FrescoesFullWidth';
import { Box } from '@mui/system';
import loginbg from '../assets/img/loginbg.jpg';

interface NotAuthWrapperProps {
    children: React.ReactNode;
}

const NotAuthWrapper: React.FC<NotAuthWrapperProps> = (props) => {
    const { t } = useTranslation();

    const isLoggedIn = useSelector((state: RootState) => state.email);

    return (
        <>
            {isLoggedIn ? (
                <Navigate replace to={t('routes.character') as string} />
            ) : (
                <>
                 <FrescouesFullWidth />
                 <Box sx={{ display: 'flex', flex: 1, backgroundImage: `url(${loginbg})`, backgroundSize: 'cover' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, background: 'rgb(14 4 4 / 70%)'}}>
                        {props.children}
                    </Box>
                 </Box>
                <FrescouesFullWidth />
                </>
            )}
        </>
    );
};

export default NotAuthWrapper;
