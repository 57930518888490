import React from 'react';
import {Typography} from "@mui/material";
import LoggedLayout from "../../component/LoggedLayout";

 const MarketView = () => {
    return (
        <LoggedLayout>
            <Typography>Market view</Typography>
        </LoggedLayout>
    )
}

export default MarketView