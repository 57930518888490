import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type AuthState = {
    email: string | null
}

const slice = createSlice({
    name: 'auth',
    initialState: { email: null } as AuthState,
    reducers: {
        setEmail: (
            state,
            { payload: { email } }: PayloadAction<{ email: string }>
        ) => {
            state.email = email
        },
        clearAuthState: (
            state,
        ) => {
            state.email = null
        },

    },
})

export const { setEmail, clearAuthState  } = slice.actions

export default slice.reducer
