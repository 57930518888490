import React from 'react';
import {Navigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { useTranslation } from 'react-i18next';

interface AuthWrapperProps {
    children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
    const { t } = useTranslation();

    const isLoggedIn = useSelector((state: RootState) => state.email);

    return (
        <>
            {isLoggedIn ? (
                props.children
            ) : (
                <Navigate replace to={t('routes.login') as string} />
            )}
        </>
    );
};

export default AuthWrapper;
