import { Button } from '@mui/material';
import React from 'react';
import btnImg from '../assets/img/btn.jpg';
import btnHoverImg from '../assets/img/btn_hover.png';
import btnFocusImg from '../assets/img/btn_clicked.png';

interface DefaultButtonProps {
    children: React.ReactNode;
}
  
const DefaultButton: React.FC<DefaultButtonProps> = ({ children }) => {
    const buttonSX = {
        background: `url(${btnImg})`,
        backgroundSize: '100% 100%',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            backgroundImage: `url(${btnHoverImg})`,
        },
        '&:active': {
            backgroundImage: `url(${btnFocusImg})`,
        },
      };
    return (
        <Button sx={ buttonSX } type="submit">
            {children}
        </Button>
    );
};

export default DefaultButton;