import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitch: React.FC = () => {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (newLanguage: 'pl' | 'en') => {
        i18n.changeLanguage(newLanguage);
    };
    
    return (
        <Box>
            <Button onClick={() => handleLanguageChange('pl')}>{t('languageSwitcher.list.polish')}</Button>
            <Button onClick={() => handleLanguageChange('en')}>{t('languageSwitcher.list.english')}</Button>
        </Box>
    );
};

export default LanguageSwitch;
