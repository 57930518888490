import React from 'react';
import {Box, Container, TextField} from "@mui/material";
import LanguageSwitch from '../../component/LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { setEmail } from '../../app/slices/authSlice';
import { useDispatch } from 'react-redux';
import NotAuthWrapper from '../../component/NotAuthWrapper';
import DefaultButton from '../../component/DefaultButton';

const LoginView = () => {
    const dispatch = useDispatch()

    const { t } = useTranslation();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if(data.get('email')) {
            dispatch(setEmail({ email: data.get('email') as string}))
        }
    }


    return (
        <NotAuthWrapper>
            <Container sx={{p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                <Box component="form" onSubmit={handleSubmit} sx={{border: '1px solid', p: 2, display: 'flex', flexDirection: 'column', gap: 2, background: 'rgb(0 0 0 / 45%)'}}>
                    <TextField
                        required
                        id="email"
                        label={t('views.login.form.fields.email.placeholder')}
                        name="email"
                        autoFocus
                        variant="filled"
                    />
                    <DefaultButton>
                        {t('views.login.form.buttons.login')}
                    </DefaultButton>
                    <DefaultButton>
                        {t('views.login.form.buttons.register')}
                    </DefaultButton>
                </Box>
            </Container>
            <LanguageSwitch />
        </NotAuthWrapper>
    )
}

export default LoginView